import axios from 'axios'
import {
  AuthControllerServiceProxy,
  CreateUserDTO,
  LoginDto,
  UserControllerServiceProxy,
  Response,
  ForgotPasswordDto,
  ResetPasswordInput,
} from 'app/services/service-proxies/service-proxies'
import {auth, googleAuthProvider, signInWithPopup} from 'setup/firebase/firebase-config'
import {UserModel} from '../models/UserModel'
import {actionTypes} from './AuthRedux'
import {put} from 'redux-saga/effects'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

const authClient = new AuthControllerServiceProxy(undefined, axios)
const userClient = new UserControllerServiceProxy(undefined, axios)

// Server should return AuthModel
export function login(email: string, password: string) {
  const payload = new LoginDto({email, password: btoa(password)})
  return authClient.login(payload)
}

export function* loginWithGoogle() {
  try {
    const {user} = yield signInWithPopup(auth, googleAuthProvider)
    let data = {
      id: user.uid,
      email: user.email,
      name: user.displayName,
      password: undefined,
      first_name: '',
      photoURL: user.photoURL,
      last_name: '',
      roles: [],
      accessToken: user.accessToken,
    }

    const userDb: Response = yield userClient.getByEmail(data.email)

    let token = user.accessToken

    if (!userDb.data) {
      const userToSave: CreateUserDTO = new CreateUserDTO()
      userToSave.init({
        googleUid: user.uid,
        name: user.displayName,
        lastName: 'N/A',
        email: user.email,
        password: user.uid,
      })
      const resp: Response = yield userClient.create(userToSave)
      token = resp.data
      data.accessToken = token
      data.roles = resp.data.roles
    } else {
      const resp: Response = yield authClient.generateToken(token)
      data = resp.data.user
      token = resp.data.accessToken
    }

    const userModel: UserModel = {
      id: data.id,
      roles: data.roles,
      name: data.name,
      lastName: '',
      email: data.email,
      googleUid: data.id,
    }

    yield put({type: actionTypes.Login, payload: {data: {accessToken: token, user: userModel}}})
  } catch (error) {}
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  const payload: ForgotPasswordDto = new ForgotPasswordDto({email})
  return authClient.forgotPassword(payload)
}

export function resetPassword(token: string, password: string) {
  const payload: ResetPasswordInput = new ResetPasswordInput({password})
  return authClient.resetPassword(token, payload)
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
