export const fileToBase64 = (file: File): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const niceBytes = (x: string) => {
  let l = 0,
    n = parseInt(x, 10) || 0

  while (n >= 1024 && ++l) {
    n = n / 1024
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}

export const replaceAll = (target: string, search: string, replacement: string) => {
  return target.replace(new RegExp(search, 'g'), replacement)
}

//A function to detect if the content is empty
export const isContentEmpty = (text: string) => {
  text = replaceAll(text, '&nbsp;', '')
  text = replaceAll(text, ' ', '')
  text = replaceAll(text, '<p>', '')
  text = replaceAll(text, '</p>', '')
  text = text.trim()

  return text.length === 0 ? true : false
}

export const enumToText = (enumType: any, value: number): string | undefined => {
  const keys = Object.keys(enumType).filter((key) => enumType[key] === value)
  return keys[0] ? keys[0] : '-'
}

export const formatDate = (date: Date): string => {
  if (!date) return '-'
  const months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ]

  if (typeof date === 'string') date = new Date(date)

  const day = date.getDate().toString().padStart(2, '0')
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export const removeNullUndefined = (obj: {[key: string]: any}): {[key: string]: any} => {
  return Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== '')
    .reduce((acc, key) => {
      acc[key] = obj[key] as any
      return acc
    }, {} as {[key: string]: any})
}

export const truncate = (str: string, length: number) => {
  if(!str) return ''
  return str.length > length ? str.substring(0, length) + '...' : str
}
