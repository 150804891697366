import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Modal, Button} from 'antd'
import clsx from 'clsx'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {APP_ROLES} from 'setup/app-constants'
import {EditUserDTO, UserControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'

const userClient = new UserControllerServiceProxy(undefined, axios)

type Props = {
  user: UserModel | undefined
  initialValues: any
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onEditUser: () => void
}

const registrationSchema = Yup.object().shape({
  id: Yup.string().required('El id requerido'),
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El primer nombre es requerido'),
  email: Yup.string()
    .email('El email no es valido')
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El correo electrónico es requerido'),
  lastName: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El apellido es requerido'),
  roles: Yup.string().required('El rol es requerido'),
})

export const EditUser: FC<Props> = ({
  user,
  initialValues,
  isModalVisible,
  setIsModalVisible,
  onEditUser,
}) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setIsModalVisible(isModalVisible)
    if (isModalVisible) {
      formik.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible])

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    validate: (values) => {
      const errors: any = {}
      if (!values.name) {
        errors.name = 'El primer nombre es requerido'
      }
      if (!values.lastName) {
        errors.lastName = 'El apellido es requerido'
      }
      if (!values.email) {
        errors.email = 'El correo electrónico es requerido'
      }
      if (!values.roles) {
        errors.roles = 'El rol es requerido'
      }
      return errors
    },
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let data: EditUserDTO = new EditUserDTO()
      data.init(values)
      data.roles = [values.roles]
      userClient
        .update(data.id, data)
        .then(() => {
          setLoading(false)
          setIsModalVisible(false)
          onEditUser()
        })
        .catch((err: any) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(err.message)
        })
    },
  })

  return (
    <Modal
      title={`Editar usuario ${user?.name}`}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      okText='Editar'
      cancelText='Cancelar'
      destroyOnClose={true}
      footer={[
        <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
          Editar Usuario
        </Button>,
      ]}
    >
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Form group name */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>
            Primer Nombre
          </label>
          <input
            placeholder='Primer Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Form group lastName */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Primer Apellido</label>
            <input
              placeholder='Primer Apellido'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastName && formik.errors.lastName,
                },
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Correo electrónico</label>
          <input
            placeholder='Correo electrónico'
            readOnly
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Rol</label>
          <select
            className={clsx(
              'form-select',
              {
                'is-invalid': formik.touched.roles && formik.errors.roles,
              },
              {
                'is-valid': formik.touched.roles && !formik.errors.roles,
              }
            )}
            {...formik.getFieldProps('roles')}
          >
            <option selected disabled>
              Seleccione un rol
            </option>
            <option value={APP_ROLES.ADMIN}>{APP_ROLES.ADMIN}</option>
            <option value={APP_ROLES.AUTHOR}>{APP_ROLES.AUTHOR}</option>
          </select>
          {formik.touched.roles && formik.errors.roles && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.roles}</span>
              </div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  )
}
