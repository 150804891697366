/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {Modal} from 'antd'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {KTSVG} from '_metronic/helpers'
import {CategoriesControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import {FilterDropdown} from 'app/shared/components/FilterDropdown'
import {Pagination} from 'app/shared/components/Pagination'
import {CreateCategory} from './CreateCategory'
import {EditCategory} from './EditCategory'
import {addTdLabel} from '_metronic/helpers/TableResponsiveHelper'

export const CategoryPage = () => {
  const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
  const [categories, setCategories] = useState<any[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [selectedFormInitialValues, setSelectedFormInitialValues] = useState<any>()
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [isActive, setIsActive] = useState<any>(undefined)
  const {confirm} = Modal

  const getCategories = async () => {
    categoryClient.findAll(isActive, keyword, 10, currentPage).then((res) => {
      res.data.Items.forEach((user: any) => (user.created_at = new Date(user.created_at)))
      setCategories(res.data.Items)
      setTotalRecords(res.data.TotalCount)
      addTdLabel()
    })
  }

  useEffect(() => {
    getCategories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, keyword, isActive])

  const onEditCategories = () => {
    getCategories()
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  const showModelEditCategory = (category: any) => {
    setIsModalVisible(true)
    setSelectedCategory(category)
    setSelectedFormInitialValues({
      id: category.id,
      name: category.name,
      isActive: category.isActive,
    })
  }

  const handleDelete = (category: any) => {
    confirm({
      title: '¿Quieres eliminar esta categoría?',
      icon: <ExclamationCircleOutlined />,
      content: 'Una vez eliminada la categoría no podrá ser recuperada',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        categoryClient.delete(category.id).then(() => {
          getCategories()
        })
      },
    })
  }

  const initialValues = {
    keyword: '',
    isActive: 'all',
  }

  const form = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setIsActive(values.isActive === 'all' ? undefined : values.isActive === 'yes' ? true : false)
      setKeyword(values.keyword)
    },
  })
  return (
    <>
      <div className='content flex-row-fluid'>
        <div className='row'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Listado de Categorias</span>
                {/* <span className='text-muted fw-bold fs-7'>Pending 10 tasks</span> */}
              </h3>
              <div className='card-toolbar'>
                {/* begin::Menu */}
                <a href='#' onClick={() => setIsModalCreateVisible(true)} className='btn btn-light'>
                  Nuevo
                </a>
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => <Tooltip {...props}>Filtros</Tooltip>}
                  placement='bottom'
                >
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary ms-5'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                </OverlayTrigger>
                {/* begin::Menu 1 */}
                <FilterDropdown>
                  <form onSubmit={form.handleSubmit} autoComplete='off'>
                    <div className='mb-10'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='nombre, apellido, correo'
                        {...form.getFieldProps('keyword')}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Esta Activa:</label>
                      <div className='d-flex  justify-content-between'>
                        <div className='form-check  form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='radio'
                            {...form.getFieldProps('isActive')}
                            value={'all'}
                            defaultChecked={true}
                          />
                          <label className='form-check-label'>Todas</label>
                        </div>
                        <div className='form-check  form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='radio'
                            {...form.getFieldProps('isActive')}
                            value={'yes'}
                          />
                          <label className='form-check-label'>Si</label>
                        </div>
                        <div className='form-check  form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='radio'
                            {...form.getFieldProps('isActive')}
                            value={'no'}
                          />
                          <label className='form-check-label'>No</label>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        onClick={() => {
                          form.resetForm()
                          setKeyword('')
                          setIsActive(undefined)
                        }}
                        className='btn btn-sm btn-white btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                      >
                        Limpiar
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        Aplicar
                      </button>
                    </div>
                  </form>
                </FilterDropdown>

                {/* end::Menu 1 */}
                {/* end::Menu */}
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-5'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder fs-6 border-bottom border-gray-200 text-gray-800'>
                      <th>Nombre</th>
                      <th>Fecha de creacion</th>
                      <th style={{textAlign: 'center'}}>Activa</th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {categories &&
                      categories.map((category) => (
                        <tr key={category.id}>
                          <td>{category.name}</td>
                          <td>{category.created_at?.toLocaleDateString()}</td>
                          <td align='center'>
                            <OverlayTrigger
                              delay={{hide: 450, show: 300}}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {category.isActive ? 'Activa' : 'Inactiva'}
                                </Tooltip>
                              )}
                              placement='bottom'
                            >
                              {category.isActive ? (
                                <i
                                  style={{fontSize: '1.4rem'}}
                                  className='fas fa-check-square text-success'
                                ></i>
                              ) : (
                                <i
                                  style={{fontSize: '1.4rem'}}
                                  className='fa fa-minus-square text-danger'
                                ></i>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td className='float-end'>
                            <Dropdown>
                              <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                Opciones
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => showModelEditCategory(category)}>
                                  <i className='fas fa-pen-alt me-2'></i> Editar
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDelete(category)}>
                                  <i className='fas fa-trash-alt me-2'></i> Eliminar
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            <Pagination
              pageSize={10}
              totalRecorsds={totalRecords}
              currentPage={currentPage}
              onPageChange={(page) => onPageChange(page)}
            />
          </div>
        </div>
      </div>
      <EditCategory
        category={selectedCategory}
        initialValues={selectedFormInitialValues}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onEditCategory={onEditCategories}
      />
      <CreateCategory
        isModalVisible={isModalCreateVisible}
        onCreateCategory={onEditCategories}
        setIsModalVisible={setIsModalCreateVisible}
      />
    </>
  )
}
