import * as Yup from 'yup'

export enum CompanyType {
  new = 'new',
  existing = 'existing',
}

export enum ContactMethod {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  WEBSITE = 'WEBSITE',
}

export enum HireType {
  TiempoCompleto = 1,
  MedioTiempo = 2,
  Temporal = 3,
  Independiente = 4,
  Pasantía = 5,
}

export interface ICreateJob {
  id?: string
  companyType: string
  companyId: string
  companyName: string
  companyUrl: string
  companyEmail: string
  companyLogoUrl: string
  title: string
  description: string
  isRemote: boolean
  category: string
  hyreType: string
  location: string
  salary?: string
  onlyImage?: boolean
  image: string
  emails: string
  phone?: string
  linkToApply?: string
  contactMethod: string
}

const defaultCompay = process.env.REACT_DEFAULT_COMPANY

const createJobSchemas = [
  Yup.object({
    companyType: Yup.mixed()
      .oneOf([CompanyType.new, CompanyType.existing])
      .required('Debe seleccionar un el company type')
      .label('Company Type'),
    companyId: Yup.string().when('companyType', {
      is: CompanyType.existing,
      then: Yup.string().required('Debe seleccionar una empresa'),
    }),
    companyName: Yup.string()
      .min(3, 'Minimo 3 caracteres')
      .max(50, 'Maximo 50 caracteres')
      .when('companyType', {
        is: CompanyType.new,
        then: Yup.string().required('El nombre de la compañia es requerido'),
      })
      .label('Nombre de la compañia'),
    companyEmail: Yup.string()
      .email('Debe ser un email valido')
      .when('companyType', {
        is: CompanyType.new,
        then: Yup.string().required('El email de la compañia es requerido'),
      })
      .label('Email de la compañia'),
    companyUrl: Yup.string().url('Debe ser una URL valida').label('URL de la compañia'),
    companyLogoUrl: Yup.string()
      .url('Debe ser una URL valida')
      .label('URL del logo de la compañia'),
  }),
  Yup.object({
    title: Yup.string()
      .min(6, 'Minimo 6 caracteres')
      .max(50, 'Maximo 50 caracteres')
      .required('El titulo es requerido')
      .label('Titulo'),
    onlyImage: Yup.boolean(),
    description: Yup.string()
      .min(10, 'Minimo 10 caracteres')
      .max(5000, 'Maximo 5000 caracteres')
      .label('Descripcion')
      .when('onlyImage', {
        is: false,
        then: Yup.string().required('La descripcion es requerida'),
      }),
    image: Yup.string().when('companyId', {
      is: true,
      then: Yup.string().required('La imagen es requerida'),
    }),
  }),
  Yup.object({
    isRemote: Yup.boolean().required('Debe indicar si es remoto'),
    category: Yup.string().required('La categoria es requerida'),
    hyreType: Yup.string().required('El tipo de contrato es requerido').label('Hire Type'),
    location: Yup.string().required('La ubicacion es requerida'),
    salary: Yup.number().label('Salario'),
    emails: Yup.string()
      .test('emails', 'Una o más direcciones de correo electrónico no son válidas', (value) => {
        if (!value) return false // Si el valor está vacío, la validación falla

        const emails = value.split(',').map((email) => email.trim())

        // Validar cada dirección de correo electrónico
        return emails.every((email) => Yup.string().email().isValidSync(email))
      })
      .when('companyId', {
        is: defaultCompay,
        then: Yup.string()
          .email('El correo electrónico no es válido')
          .required('Debe ingresar un correo de contacto'),
      }),
    phone: Yup.string(),
    linkToApply: Yup.string(),
    contactMethod: Yup.string().required('El metodo de contacto es requerido'),
  }).test({
    name: 'at-least-one-field',
    test: function (value) {
      const {emails, phone, linkToApply, contactMethod} = value
      const email = emails?.split(',').map((email) => email.trim()) || []
      if (contactMethod === ContactMethod.EMAIL && !email.length) {
        return this.createError({
          path: 'emails',
          message: 'Debe ingresar al menos un correo de contacto',
        })
      }

      if (contactMethod === ContactMethod.PHONE && !phone) {
        return this.createError({
          path: 'phone',
          message: 'Debe ingresar un numero de telefono de contacto',
        })
      }

      if (contactMethod === ContactMethod.WEBSITE && !linkToApply) {
        return this.createError({
          path: 'linkToApply',
          message: 'Debe ingresar un link de contacto',
        })
      }

      return true
    },
  }),
]

const inits: ICreateJob = {
  companyType: 'existing',
  companyId: '',
  companyName: '',
  companyUrl: '',
  companyEmail: '',
  companyLogoUrl: '',
  title: '',
  description: '',
  isRemote: false,
  category: '',
  hyreType: '',
  location: '',
  salary: '0',
  onlyImage: false,
  image: '',
  emails: '',
  phone: '',
  linkToApply: '',
  contactMethod: ContactMethod.EMAIL,
}

export {createJobSchemas, inits}
