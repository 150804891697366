/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage, useField} from 'formik'

type Props = {
  companies: any[]
}

const JobStep1: FC<Props> = ({companies}) => {
  const [field] = useField('companyType')

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Elige la compania de la vacante
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='companyType'
              value='existing'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Usar compañía existente
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='companyType'
              value='new'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Crear nueva compañía</span>
              </span>
            </label>
          </div>

          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='accountType' />
          </div>
        </div>
      </div>
      {field.value === 'new' && (
        <div className='fv-row'>
          <div className='row'>
            <div className='col-lg-6'>
              <label className='d-flex align-items-center form-label mb-3 required'>
                Nombre
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Nombre de la compañía'
                ></i>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='companyName'
                placeholder=''
              />
              <ErrorMessage name='companyName'>
                {(msg) => <div style={{color: 'red'}}>{msg}</div>}
              </ErrorMessage>
            </div>
            <div className='col-lg-6'>
              <label className='d-flex align-items-center form-label mb-3 required'>
                Correo electronico
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Correo electronico de la compañía'
                ></i>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='companyEmail'
                placeholder=''
              />
              <div id='companyEmail' className='form-text'>
                Tip: Asegurate de que el correo electronico sea valido, ya que sera el que se
                utilizara para hacerte llegar los curriculum vitae de los candidatos.
              </div>
              <ErrorMessage name='companyEmail'>
                {(msg) => <div style={{color: 'red'}}>{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <label className='d-flex align-items-center form-label mb-3'>
                Sitio Web (opcional)
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Sitio web de la compañía'
                ></i>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='companyUrl'
                placeholder='Sitio Web'
              />
              <ErrorMessage name='companyUrl'>
                {(msg) => <div style={{color: 'red'}}>{msg}</div>}
              </ErrorMessage>
            </div>
            <div className='col-lg-6'>
              <label className='d-flex align-items-center form-label mb-3'>
                Logo de la empresa (.jpg, .png) (opcional)
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Tip: Asegurate de que el servidor donde esté alojado el logo acepte hotlinks. Si no sabes que es eso, no te preocupes, sube el logo a imgur y pega la url aquí'
                ></i>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='companyLogoUrl'
                placeholder='Logo de la empresa'
              />
              <ErrorMessage name='companyLogoUrl'>
                {(msg) => <div style={{color: 'red'}}>{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
        </div>
      )}
      {field.value === 'existing' && (
        <div className='fv-row'>
          <div className='separator separator-dotted border-dark my-10'></div>
          <Field
            as='select'
            name='companyId'
            className='form-select form-select-lg form-select-solid'
          >
            <option value='' disabled>
              Selecciona una compañía
            </option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name='companyId'>
            {(msg) => <div style={{color: 'red'}}>{msg}</div>}
          </ErrorMessage>
        </div>
      )}
    </div>
  )
}

export {JobStep1}
