/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Image, Empty, Modal} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {
  CategoriesControllerServiceProxy,
  CompanyControllerServiceProxy,
  GetCategoryDto,
  GetLocationDto,
  HireTypeControllerServiceProxy,
  JobControllerServiceProxy,
  LocationsControllerServiceProxy,
  Status,
} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {APP_COMPANY_DEFAULT_LOGO} from 'setup/app-constants'
import {Pagination} from 'app/shared/components/Pagination'
import {ToggleButton, OverlayTrigger, Tooltip, ToggleButtonGroup} from 'react-bootstrap'
import {FilterDropdown} from 'app/shared/components/FilterDropdown'
import {useFormik} from 'formik'
import {TimeAgo} from 'app/shared/components/TimeAgo'
import {KTSVG} from '_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {ManageJob} from './ManageJob'
import {TimeDiff} from 'app/shared/components/TimeDiff'
import {NewTag} from 'app/shared/components/NewTag'
import {addTdLabel} from '_metronic/helpers/TableResponsiveHelper'
import clsx from 'clsx'
import {Tab, Tabs} from 'react-bootstrap'

export interface IFilters {
  keyword?: string
  location?: string
  hyreType?: string
  category?: string
  postedBy?: string
  company?: string
  isActive?: boolean
  isRemote?: boolean
  isApproved?: string
}

export const JobsManagement = () => {
  const navigate = useNavigate()
  const [categories, setCategories] = React.useState([])
  const [locations, setLocation] = React.useState([])
  const [companies, setCompanies] = useState<any[]>([])
  const [hyreTypes, setHyreTypes] = useState<any[]>([])
  const [jobs, setJobs] = React.useState<any[]>([])
  const [isModalEditVisible, setIsModalEditVisible] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<IFilters>({})
  const [editModel, setEditModel] = useState<any>(undefined)

  const [status, setStatus] = useState<Status>(Status.All);

  const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
  const locationClient = new LocationsControllerServiceProxy(undefined, axios)
  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const companyClient = new CompanyControllerServiceProxy(undefined, axios)
  const hyreTypeClient = new HireTypeControllerServiceProxy(undefined, axios)

  const {confirm} = Modal

  const pageSize = 5

  const getJobs = () => {
    const isApproved = filters.isApproved === 'yes' ? true : filters.isApproved === 'no' ? false : undefined


    jobClient
      .findAll(
        status,
        undefined,
        isApproved,
        filters.isRemote,
        filters.company,
        undefined,
        filters.category,
        filters.hyreType,
        filters.location,
        filters.keyword,
        pageSize,
        currentPage
      )
      .then((res: any) => {
        res.data.Items.forEach((user: any) => {
          user.created_at = new Date(user.created_at)
          if (user.publishedFrom) user.publishedFrom = new Date(user.publishedFrom)
          if (user.publishedTo) user.publishedTo = new Date(user.publishedTo)
        })
        setJobs(res.data.Items)
        setTotalRecords(res.data.TotalCount)
        addTdLabel()
      })
  }

  const getFilters = async () => {
    const [categories, locations, companies, hyreTypes] = await Promise.all([
      categoryClient.getAllWithoutPagination(),
      locationClient.getAllWithoutPagination(),
      companyClient.getAllWithoutPagination(undefined),
      hyreTypeClient.getAllWithoutPagination(),
    ])

    setCategories(categories.data)
    setLocation(locations.data)
    setCompanies(companies.data)
    setHyreTypes(hyreTypes.data)
  }

  useEffect(() => {
    getFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters])

  const initialValues: IFilters = {
    keyword: '',
    location: '',
    hyreType: '',
    category: '',
    postedBy: '',
    company: '',
    isActive: undefined,
    isRemote: undefined,
  }

  const form = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setFilters(values)
    },
  })

  const onEditLocations = () => {
    getJobs()
  }

  const handleChangeState = (jobId: string) => {
    confirm({
      title: 'Esta seguro de cambiar el estado a pendiente?',
      icon: <ExclamationCircleOutlined />,
      content: 'Una vez cambiado el estado la vacante dejará de estar visible en el sitio',
      onOk() {
        jobClient.setPendingState(jobId).then(() => {
          getJobs()
        })
      },
    })
  }

  const tabContent = () => {
    return (
      <>
        {jobs?.length > 0 && (
          <div>
            <table className='table table-rounded table-striped gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th></th>
                  <th>Titulo</th>
                  <th>Vigencia</th>
                  <th>Creado</th>
                  <th>Jornada</th>
                  <th>Estado</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => {
                  return (
                    <tr key={job.id}>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <Image
                              width={100}
                              src={
                                job?.company?.logoUrl
                                  ? job.company.logoUrl
                                  : APP_COMPANY_DEFAULT_LOGO.url
                              }
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <p className='text-wrap'>
                          <a
                            onClick={() => {
                              let model = {
                                id: job.id,
                                companyType: 'existing',
                                companyId: job?.company?.id.toString(),
                                companyName: '',
                                companyUrl: '',
                                companyEmail: '',
                                companyLogoUrl: '',
                                title: job.title,
                                description: job.description,
                                isRemote: job?.isRemote,
                                category: job?.category?.id.toString(),
                                hyreType: job?.hyreType?.id.toString(),
                                location: job?.location?.id.toString(),
                                salary: job.salary ? job.salary : 0,
                              }
                              setEditModel(model)
                              setIsModalEditVisible(true)
                            }}
                            className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                          >
                            {job.title}{' '}
                          </a>
                          {job.isRemote && <span className='badge badge-primary'>Remoto</span>}
                        </p>
                        <p style={{whiteSpace: 'nowrap'}}>
                          <i className='fas fa-building'></i> {job.company ? job.company.name : "-"}
                          <i className='fas fa-map-marked-alt ms-3'></i> {job.location ? job.location.name : "-"}
                        </p>
                        <NewTag
                          key={job.id}
                          date={job.created_at}
                          removeAfter={'1h'}
                          tag={'Nuevo'}
                        />
                      </td>
                      <td
                        className='text-muted fw-bold'
                        style={{fontSize: 'smaller', whiteSpace: 'nowrap'}}
                      >
                        {job.publishedFrom ? (
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {job.publishedFrom.toLocaleDateString()} {' - '}
                                {job.publishedTo.toLocaleDateString()}
                              </Tooltip>
                            )}
                            placement='bottom'
                          >
                            <span
                              className={
                                job.publishedTo < new Date()
                                  ? 'badge badge-warning'
                                  : 'badge badge-info'
                              }
                            >
                              <TimeDiff from={job.publishedFrom} to={job.publishedTo} />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td className='text-muted fw-bold'>
                        <OverlayTrigger
                          delay={{hide: 450, show: 300}}
                          overlay={(props) => (
                            <Tooltip {...props}>{job.created_at.toLocaleString()}</Tooltip>
                          )}
                          placement='bottom'
                        >
                          <span className='badge badge-light-primary'>
                            <TimeAgo time={job.created_at} />
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <span className='badge badge-light-success'>{job?.hyreType?.name}</span>
                      </td>
                      <td>
                        <span
                          className={`badge ${job?.isApproved ? 'badge-success' : 'badge-danger'}`}
                        >
                          {job.isApproved ? 'Aprobada' : 'Pendiente'}
                        </span>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex'>
                          {job.isApproved && (
                            <OverlayTrigger
                              delay={{hide: 450, show: 300}}
                              overlay={(props) => <Tooltip {...props}>Cambiar a pendiente</Tooltip>}
                              placement='bottom'
                            >
                              <a
                                onClick={() => {
                                  handleChangeState(job.id)
                                }}
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger me-2'
                              >
                                <i className='fas fa-exchange-alt'></i>
                              </a>
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            overlay={(props) => <Tooltip {...props}>Visualizar</Tooltip>}
                            placement='bottom'
                          >
                            <a
                              onClick={() => {
                                navigate(`/ofertas-de-empleo/${job.slug}`)
                              }}
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-info me-2'
                            >
                              <i className='fas fa-eye'></i>
                            </a>
                          </OverlayTrigger>
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            overlay={(props) => <Tooltip {...props}>Gestionar</Tooltip>}
                            placement='bottom'
                          >
                            <a
                              onClick={() => {
                                setEditModel(job)
                                setIsModalEditVisible(true)
                              }}
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <i className='fas fa-cogs'></i>
                            </a>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              pageSize={pageSize}
              totalRecorsds={totalRecords}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
        {jobs?.length === 0 && <Empty description={'No hay datos disponibles'} />}
      </>
    )
  }

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col card card-xxl-stretch  mb-5 mb-xl-10'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Listado de gestión de vacantes</span>
            </h3>
            <div className='card-toolbar'>
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => <Tooltip {...props}>Filtros</Tooltip>}
                placement='bottom'
              >
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary ms-5'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
              </OverlayTrigger>
              <FilterDropdown className='w-md-400px'>
                <form onSubmit={form.handleSubmit} autoComplete='off'>
                  <div className='mb-10'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Título, Descripción'
                      {...form.getFieldProps('keyword')}
                    />
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Estado:</label>
                    <div className='d-flex  justify-content-between'>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isApproved')}
                          value={'all'}
                          defaultChecked={true}
                        />
                        <label className='form-check-label'>Todas</label>
                      </div>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isApproved')}
                          value={'no'}
                        />
                        <label className='form-check-label'>Pendientes</label>
                      </div>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isApproved')}
                          value={'yes'}
                        />
                        <label className='form-check-label'>Aprobadas</label>
                      </div>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Categoría:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('category')}
                      >
                        <option value='' disabled>
                          Selecciona una categoria
                        </option>
                        {categories.map((option: GetCategoryDto) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Localización:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('location')}
                      >
                        <option value='' disabled>
                          Selecciona una localización
                        </option>
                        {locations.map((option: GetLocationDto) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Compañía:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('company')}
                      >
                        <option value='' disabled>
                          Selecciona una compañía
                        </option>
                        {companies.map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Jornada Laboral:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('hyreType')}
                      >
                        <option value='' disabled>
                          Selecciona una jornada
                        </option>
                        {hyreTypes.map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        {...form.getFieldProps('isRemote')}
                        name='isRemote'
                      />
                      <label className='form-check-label'>¿Es un puesto remoto?</label>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        form.resetForm()
                        setFilters(initialValues)
                      }}
                      className='btn btn-sm btn-white btn-active-light-primary me-2'
                      data-kt-menu-dismiss='true'
                    >
                      Limpiar
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                    >
                      Aplicar
                    </button>
                  </div>
                </form>
              </FilterDropdown>
            </div>
          </div>
          <div className='card-body py-3'>
            <Tabs
              defaultActiveKey='home'
              onSelect={(k) => {
                switch (k) {
                  case 'pendings':
                    setStatus(Status.Pending)
                    getJobs()
                    
                    break
                  case 'current':
                    setStatus(Status.Current)
                    getJobs()
                    break
                  case 'defeated':
                    setStatus(Status.Defeated)
                    getJobs()
                    break
                  default:
                    setStatus(Status.All)
                    getJobs()
                    break
                }
              }}
            >
              <Tab eventKey='home' title='Todas'>
                {tabContent()}
              </Tab>
              <Tab eventKey='pendings' title='Pendientes'>
                {tabContent()}
              </Tab>
              <Tab eventKey='current' title='Vigentes'>
                {tabContent()}
              </Tab>
              <Tab eventKey='defeated' title='Vencidas'>
                {tabContent()}
              </Tab>
            </Tabs>
          </div>
          {/* end::Body */}
        </div>
      </div>
      {isModalEditVisible && (
        <ManageJob
          onApproveJob={onEditLocations}
          setIsModalVisible={setIsModalEditVisible}
          isModalVisible={isModalEditVisible}
          initFormValues={editModel}
        />
      )}
    </>
  )
}
